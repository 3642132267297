

























import {Component, Prop, Vue} from 'vue-property-decorator';
import ITerm from '@/types/ITerms';

@Component({name: 'AcceptedTerms'})
export default class RegistrationForm extends Vue {

  @Prop()
  terms!: ITerm[];

}
