





























import {Component, Vue, Watch} from 'vue-property-decorator';
import IUserForm from '@/types/IUserForm';
import {alpha, required} from '@/utils/validationRules';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import UserService from '@/services/UserService';
import {namespace} from 'vuex-class';
import AuthService from '@/services/AuthService';

const user = namespace('user');
const layout = namespace('layout');

@Component({name: 'ProfileForm'})
export default class ProfileForm extends Vue {
  loading: boolean = false;
  form: IUserForm = {
    firstName: '',
    lastName: '',
  };

  rules = {
    firstName: [required, alpha],
    lastName: [required, alpha],
  };

  @user.State
  firstName!: string;

  @user.State
  lastName!: string;

  @layout.State
  width!: number;

  @Watch('firstName')
  onFirstNameChanged() {
    this.form.firstName = this.firstName;
  }

  @Watch('lastName')
  onLastNameChanged() {
    this.form.lastName = this.lastName;
  }

  mounted() {
    this.form.firstName = this.firstName;
    this.form.lastName = this.lastName;
  }

  async save() {
    try {
      this.loading = true;
      // @ts-ignore
      if (this.$refs.form.validate()) {
        await UserService.updateUser(this.form);
        await AuthService.refreshToken();
        await UserService.getCurrentUser();
        notify(NotificationTypes.success, this.$t('SYSTEM_UPDATED'));
      }
    } catch (e) {
      resolveError(e, 'edit');
    } finally {
      this.loading = false;
    }
  }
}
